// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function testing() {
  var popup = buildPopup({ident: 'testing'});
  ajax({
    object: 'settings',
    send: {func: 'findTests'},
    callback: function (xhr) {
      window.testingMode = true;
      $.each(xhr.tests, function (i, row) {
        var paramIn = JSON.parse(row.param);
        if (
          isString(paramIn.value) &&
          paramIn.value.match(/now|time|year|user|store/)
        )
          return;

        var paramKeys = getParams(window[row.func]);
        var pm = [];
        $.each(paramKeys, function (i, key) {
          pm.push(paramIn[key]);
        });
        var result = window[row.func].apply(this, pm);
        if (row.result === 'true') row.result = true;
        else if (row.result === 'false') row.result = false;

        var info = row.func;
        if (paramIn && paramIn.value) info += ' : ' + paramIn.value;
        if (row.result) info += ' = ' + row.result;
        if (row.result != result) info += ' != ' + result;
        info = $('<div>' + info + '</div>').appendTo(popup);
        info.one('mouseover', function () {
          info
            .addTooltip('<pre>' + JSON.stringify(row, null, 2) + '</pre>')
            .trigger('mouseover');
        });

        if (result != row.result) {
          info.prepend('<i class="fal fa-exclamation-triangle"></i> ');
          return false;
        }
      });
      window.testingMode = false;
    },
  });
}

// get function parameters
// eslint-disable-next-line no-redeclare,no-unused-vars
function getParams(func) {
  // String representaation of the function code
  var str = func.toString();

  // Remove comments of the form /* ... */
  // Removing comments of the form //
  // Remove body of the function { ... }
  // removing '=>' if func is arrow function
  str = str
    .replace(/\/\*[\s\S]*?\*\//g, '')
    .replace(/\/\/(.)*/g, '')
    .replace(/{[\s\S]*}/, '')
    .replace(/=>/g, '')
    .trim();

  // Start parameter names after first '('
  var start = str.indexOf('(') + 1;

  // End parameter names is just before last ')'
  var end = str.length - 1;

  var result = str.substring(start, end).split(',');

  var params = [];

  result.forEach((element) => {
    // Removing any default value
    element = element.replace(/=[\s\S]*/g, '').trim();

    if (element.length > 0) params.push(element);
  });

  return params;
}
